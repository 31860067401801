import { useSuperServices } from "hooks/useService";
import { useBlogs_by_category } from "hooks/useUser";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppStoreButton from "theme/buttons/AppStoreButton";
import PlayStoreButton from "theme/buttons/PlayStoreButton";

const TrendingPosts = () => {
  const navigate = useNavigate();
  const { isLoading, data: super_services } = useSuperServices();

  const {
    data: trending_data,
    isLoading: trending_isLoading,
    mutate,
  } = useBlogs_by_category();

  useEffect(() => {
    const payload = {
      blog_category_id: 1,
    };
    mutate(payload);
  }, []);

  const handleClick = (item) => {
    if (item?.super_category_web_seo_url !== "") {
      setTimeout(() => {
        window.location.reload();
      }, 300);
      navigate(item?.super_category_web_seo_url.match(/\/([^/]+)$/)[0], {
        state: {
          web_banner_image: item?.web_banner_image,
          web_banner_text: item?.web_banner_text,
          super_category_id: item?.super_category_id,
          super_category_name: item?.super_category_name,
          category_type: "category",
          super_category_description: item?.super_category_description,
        },
      });
    } else {
      navigate(`/services`, {
        state: {
          web_banner_image: item?.web_banner_image,
          web_banner_text: item?.web_banner_text,
          category_id: item?.super_category_id,
          super_category_name: item?.super_category_name,
          category_type: "category",
          super_category_description: item?.super_category_description,
        },
      });
    }
  };

  const handleBlogsDetailPage = (item) => {
    navigate(`/blogs/${item?.slug}`, {
      state: {
        blogs_detail_data: item?.slug,
      },
    });
  };

  return (
    <>
      <div className="w-full md:max-w-[369px] mx-auto flex flex-col gap-4">
        {/* ----------------------------- Trending Posts ----------------------------- */}
        <div className="w-full max-h-[800px] font-Trueno border border-[#DFDFDF] rounded-2xl p-3 md:p-6 overflow-y-auto">
          <div className="flex flex-col gap-4">
            <h3 className="font-extrabold text-2xl">Trending Posts</h3>
            {trending_isLoading
              ? Array(3)
                  .fill()
                  .map((_, index) => (
                    <div key={index} className="flex gap-2">
                      <div className="animate-pulse h-[12.22px] w-[122.22px] bg-sky-200"></div>
                    </div>
                  ))
              : trending_data?.data?.map((item, index) => (
                  <div className="flex gap-1 items-center w-fit" key={index}>
                    <img
                      src={item?.banner_image}
                      alt={item?.banner_image_alt}
                      className="object-cover w-[62px] h-[62px] rounded-lg"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        handleBlogsDetailPage(item);
                      }}
                      className="w-full py-1 px-3 cursor-pointer font-bold text-base text-left hover:scale-105 hover:duration-200 hover:text-[#0973BA]"
                    >
                      {item?.title?.length > 45
                        ? item?.title.substring(0, 45) + "...."
                        : item?.title}
                    </button>
                  </div>
                ))}
          </div>
        </div>

        {/* ----------------------------- Our Services ----------------------------- */}

        <div className="w-full h-fit font-Trueno border border-[#DFDFDF] rounded-2xl p-3 md:p-6">
          <div className="flex flex-col gap-4">
            <h3 className="font-extrabold text-2xl">Our Services</h3>
            {isLoading
              ? Array(3)
                  .fill()
                  .map((_, index) => (
                    <div
                      key={index}
                      className="animate-pulse h-[12.22px] w-[122.22px] bg-sky-200"
                    ></div>
                  ))
              : super_services?.data?.en?.map((item, index) => (
                  <div key={index}>
                    <button
                      type="button"
                      onClick={() => handleClick(item)}
                      className="w-full py-1 px-3 cursor-pointer font-semibold text-base hover:text-[#0973BA] border-l-[3.84px] border-[#0973BA] text-left hover:scale-105 hover:duration-200 ease-in"
                    >
                      {item?.super_category_name}
                    </button>
                  </div>
                ))}
            <button
              type="button"
              onClick={() => navigate("/")}
              className="w-full font-bold text-base text-white text-center bg-[#0973BA] rounded-lg py-3 "
            >
              View all
            </button>
          </div>
        </div>

        {/* ----------------------------- Download the app ----------------------------- */}

        <div className="flex flex-col gap-4 items-center rounded-2xl bg-[#D2E4F3] pb-5 px-2">
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/images/MobileGetMoreDoneIcon.svg`}
              alt=""
              className="object-cover"
            />
          </div>
          <h3 className="font-extrabold text-2xl text-center ">
            Download
            <span className="text-[#0973BA]"> Karsaaz </span>
            Mobile App Now
          </h3>
          <div className="grid gap-2">
            <PlayStoreButton />
            <AppStoreButton />
          </div>
        </div>
      </div>
    </>
  );
};

export default TrendingPosts;
