import React from "react";
import { HomePageSEOContent } from "helpers/constants";

const Anchor = ({ href, children }) => (
  <a
    href={href}
    rel="noopener noreferrer"
    className="font-bold text-[#07609C] underline cursor-pointer"
  >
    {children}
  </a>
);

const HomeServices = () => {
  return (
    <div className="w-[90%] mx-auto flex flex-col gap-3">
      <h2 className="font-semibold text-[26px] leading-[31.69px]">
        Affordable Home Maintenance and Repairing Services in Pakistan
      </h2>
      <p className="font-normal text-[18px] leading-8">
        Finding home services in Pakistan, which are reliable, can become very
        difficult. However, with our expert solutions, you don't have to worry
        about your household maintenance and repair needs.{" "}
        <Anchor href="https://karsaaz.app">Karsaaz</Anchor> provides top-class,
        affordable, and professional home services that range from AC service to
        house cleaning services in Lahore, Islamabad, Karachi and Rawalpindi and
        all other things between them. Whether you need urgent repairs or
        regular upkeep, our team is here to ensure your home remains in perfect
        condition.
      </p>

      <h2 className="font-semibold text-[26px] leading-[31.69px]">
        Complete Home Services for Everyone
      </h2>
      <p className="font-normal text-[18px] leading-8">
        We are well aware that the household requires multiple aspects of
        service. This ranges from{" "}
        <Anchor href="https://karsaaz.app/bridal-makeup-services">
          bridal makeup
        </Anchor>{" "}
        to{" "}
        <Anchor href="https://karsaaz.app/Party-Makeup-and-Hairstyle">
          party makeup
        </Anchor>{" "}
        and from electrical to plumbing and from appliances to furniture and
        from all beauty to{" "}
        <Anchor href="https://www.karsaaz.app/makeup-services-at-home">
          makeup
        </Anchor>
        . Hence, <Anchor href="https://karsaaz.app">Karsaaz</Anchor> offers you
        an all-around service in a range of home services, tailored to fulfill
        every need in your house.
      </p>
      {HomePageSEOContent.map((item, index) => (
        <div key={index} className="flex flex-col gap-3">
          <div
            className="font-semibold text-[26px] leading-[31.69px] mt-3"
            dangerouslySetInnerHTML={{ __html: item?.title }}
          ></div>
          <p
            className="font-normal text-[18px] leading-8"
            dangerouslySetInnerHTML={{ __html: item?.description }}
          />
        </div>
      ))}

      <h2 className="font-semibold text-[26px] leading-[31.69px]">
        Why Choose Karsaaz Home Services?
      </h2>
      <ul className="list-decimal list-inside p-2 font-normal text-[18px] leading-8 flex flex-col gap-3">
        <li>
          <strong>Experienced Professionals:</strong> Our skilled technicians
          ensure quality service with every job.
        </li>
        <li>
          <strong>Affordable Pricing:</strong> Quality home services for a
          budgeted price.
        </li>
        <li>
          <strong>Quick & Convenient:</strong> Simply book the services and get
          it done at a time of your choice.
        </li>
        <li>
          <strong>Guaranteed Satisfaction:</strong> Customer satisfaction is our
          prime concern. Reliable, long-term solutions guaranteed. Book Your
          Home Services Today! Keeping your home in perfect shape has never been
          easier. Whether you need bridal makeup,{" "}
          <Anchor href="https://karsaaz.app/mehndi-design-service">
            mehndi design
          </Anchor>
          , appliance repair, house cleaning services, or solar services, we've
          got you covered. With premium products and expert techniques,{" "}
          <Anchor href="https://play.google.com/store/apps/details?id=com.KarsaazPro.app&hl=en">
            Karsaaz
          </Anchor>{" "}
          App guarantees that you will look and feel fabulous. Download the app
          now and book your service for changing beauty into a no-hassle
          rejuvenating experience! Contact us today and experience hassle-free
          home services in Pakistan!
        </li>
      </ul>
    </div>
  );
};

export default HomeServices;
