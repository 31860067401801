import RightSideBar from "common/RightSideBar";
import React, { useState } from "react";
import NavCustom from "views/partials/navbar/NavCustom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PopularServiceButtons from "common/PopularServiceButtons";
import { useBanner } from "hooks/useService";
import { CarouselSettings } from "helpers/constants";
import SearchBar from "common/SearchBar";

const HomeCarousel = () => {
  const { data: banner, isLoading } = useBanner();

  const [setDefaultImage] = useState({});

  const handleErrorImage = (banner) => {
    setDefaultImage((prev) => ({
      ...prev,
      [banner.target.alt]: banner.target.alt,
      linkDefault: `${process.env.PUBLIC_URL}/images/imgGirl.png`,
    }));
  };


  return (

    <div className="flex flex-col max-w-full h-full bg-[#07609C]">
      <NavCustom styling={{ color: "white", backgroundColor: "#07609C" }} />

      <RightSideBar />

      {/* Slider section  */}
      {isLoading ? (
        <div>
          <div className="w-[90%] mx-auto flex flex-col-reverse md:grid md:grid-cols-2 p-3 py-10">
            <div className="w-full flex flex-col gap-3 pt-5 md:py-12">
              <div className="animate-pulse w-full h-7 bg-sky-200"></div>
              <div className="animate-pulse w-1/4 h-7 bg-sky-200"></div>

              <div className="animate-pulse w-1/3 h-4 bg-sky-200 mt-3"></div>
              <div className="animate-pulse w-full h-14 bg-sky-200 mt-3 rounded-2xl"></div>
              <div className="animate-pulse w-1/3 h-7 bg-sky-200"></div>
              <div className="space-y-4 mt-4">
                <div className="hidden md:flex items-center gap-3">
                  <div className="animate-pulse w-full h-5 bg-sky-200 rounded-lg"></div>
                  <div className="animate-pulse w-full h-5 bg-sky-200 rounded-lg"></div>
                  <div className="animate-pulse w-full h-5 bg-sky-200 rounded-lg"></div>
                  <div className="animate-pulse w-full h-5 bg-sky-200 rounded-lg"></div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center gap-5 w-full">
              <div className=" animate-pulse w-[100px] h-[200px] rounded-t-full rounded-b-full bg-slate-200"></div>
              <div className=" animate-pulse w-[200px] h-[300px]  rounded-t-full rounded-b-full bg-slate-200"></div>
            </div>
          </div>
          <div className="flex items-center justify-center gap-5 w-fit mx-auto py-5 md:py-11">
            <div className="animate-pulse w-1 h-1 bg-sky-200 rounded-full"></div>
            <div className="animate-pulse w-1 h-1 bg-sky-200 rounded-full"></div>
            <div className="animate-pulse w-1 h-1 bg-sky-200 rounded-full"></div>
          </div>
        </div>
      ) : (
        <div className="font-Trueno pb-8 h-full">
          <Slider {...CarouselSettings}>
            {banner?.data?.map((item, index) => (
              <div key={index}>
                <div className="outline-none flex flex-col md:grid md:grid-cols-2 justify-between w-[90%] mx-auto place-items-center justify-items-center">
                  {/* <------------- Hero----Section----Paragraph-------- > */}
                  <div className="h-auto w-full">
                    <div className="inner-html-style" dangerouslySetInnerHTML={{ __html: item?.banner_text }}></div>

                    {/* ------------------------------- Search Bar Custom ------------------------------- */}

                    <p className="text-white lg:font-normal lg:text-[20px]  mt-5 mb-4">Search the Service You Need</p>
                    <div className="h-full w-full">
                      <SearchBar />
                    </div>
                    <div className="mt-[14px] lg:block hidden ">
                      <p className="text-white font-normal md:text-[14px] ">Popular Services</p>
                      <div className="">
                        <PopularServiceButtons />
                      </div>
                    </div>
                  </div>

                  {/* <------------- Hero----Section----Image-------- > */}

                  <div className="w-fit">
                    <img className="max-w-full h-auto" src={item?.web_picture} alt={item.title} onError={handleErrorImage} />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default HomeCarousel;
