export const IS_USER_LOGGED_IN = "isUserLoggedIn";

export const CarouselSettings = {
  className: "slider variable-width",
  dots: true,
  arrows: false,
  lazyLoad: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnDotsHover: false,
  pauseOnFocus: false,
  pauseOnHover: true,
};

export const CustomerCardsSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 610,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const Collaborators_settings = {
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 2000,
  cssEase: "linear",

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 610,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

export const Weekly_offers = {
  dots: false,
  infinite: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 2000,
  arrows: false,
  slidesToShow: 3,
  adaptiveHeight: true,

  variableWidth: true,
  className: "slider variable-width",

  responsive: [
    {
      breakpoint: 1252,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1227,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },

    {
      breakpoint: 941,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 630,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const BlogsSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  autoplay: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 610,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const BlogsPopularServices = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  initialSlide: 0,

  responsive: [
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 610,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const RedirectionURLs = [
  {
    url: "/deep-cleaning-services-lahore",
    redirect: "/cleaning-services-lahore",
  },
  {
    url: "/half-body-wax-rawalpindi",
    redirect: "/full-body-face-sugar-wax-service-rawalpindi",
  },
  {
    url: "/contact",
    redirect: "/contact-us",
  },
  {
    url: "/index.php",
    redirect: "/",
  },
  {
    url: "/massage-service-female-rawalpindi",
    redirect: "/Massage-Service-for-Females",
  },
  {
    url: "/manicure-and-pedicure-at",
    redirect: "/blogs",
  },
  {
    url: "/ac-services-",
    redirect: "/ac-services-and-repair",
  },
  {
    url: "/search",
    redirect: "/",
  },
  {
    url: "/best-instant-gas-geyser",
    redirect: "/Geyser-Services",
  },
  {
    url: "/signup-question",
    redirect: "/register",
  },
  {
    url: "/Deep-Cleaning-Services-Lahore",
    redirect: "/cleaning-services-lahore",
  },
  {
    url: "/Half-Body-Wax-Service",
    redirect: "/half-body-sugar-wax-service-lahore",
  },
  {
    url: "/karsaaz-terms-and-conditions-",
    redirect: "/karsaaz-terms-and-conditions",
  },
  {
    url: "/half-body-wax-islamabad",
    redirect: "/full-body-wax-service-islamabad",
  },
];

export const HomePageSEOContent = [
  {
    title: `<h3>Effective AC Service</h3>`,
    description: `Beat the heat with our expert <a href="https://karsaaz.app/ac-services-and-repair" target="_blank" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">AC service</a> and AC installation solutions. Whether you need routine maintenance, gas refilling, or a new installation, our skilled technicians ensure your air conditioning system works efficiently all year round.`,
  },
  {
    title: `<h3>Appliance Repair Solutions</h3>`,
    description: `Faulty appliance in the house? Our <a href="https://karsaaz.app/appliances-installation-repair-service" target="_blank" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">appliance repair</a> experts can mend all types of home appliances and make them operate smoothly and effectively. From a fridge repair near me to a <a href="https://karsaaz.app/washing-machine-repair" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">washing machine repair </a>, we bring you quick, reliable solutions for keeping your house running without much disruption.`,
  },
  {
    title: `<h3>Carpentry & Furniture Repairs</h3>`,
    description: `Need furniture repair or customized woodwork? Our <a href="https://karsaaz.app/carpenter-service-at-home" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">carpentry services </a> offer excellent workmanship for furniture repair, door fittings, and wood polishing for beautifying the durability of home interiors.`,
  },
  {
    title: `<h3>Electrical Repairs & Installation</h3>`,
    description: `Electricity issues are a danger to homes if they are not addressed with care. Professional <a href="https://karsaaz.app/electrician-services" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">electrician services </a> at our site cover wiring, switchboard installations, and any kind of electrical troubleshooting that makes your home safe.`,
  },
  {
    title: `<h3>Geyser & Plumbing Repair</h3>`,
    description: `No hot water? Leaking pipes? Our <a href="https://karsaaz.app/geyser-repair-installation-service" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">geyser repair</a> and <a href="https://karsaaz.app/plumbing-services" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">plumbing services</a>
      guarantee smooth water supply with professional leak detection, pipe repairs, and fixture installations.`,
  },
  {
    title: `<h3>House Painting & Renovation</h3>`,
    description: `Make your house beautiful with our professional <a href="https://karsaaz.app/home-paint-services" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">house painting service</a>. We provide you with the top-notch interior and exterior painting that gives your house a fresh and stylish look and protects it from environmental damage.`,
  },
  {
    title: `<h3>Pest Control for a Healthier Home</h3>`,
    description: `Our  <a href="https://karsaaz.app/pest-control-services" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">pest control </a> services ensure a pest-free home for you. Cockroaches, termites, bed bugs, and rodents get expert treatment through our exterminators by using safe treatments and solutions.`,
  },
  {
    title: `<h3>Deep Cleaning Services</h3>`,
    description: `A clean home is a happy home. Through <a href="https://karsaaz.app/professional-home-cleaning-services" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">house cleaning</a>, deep cleaning and sanitization is guaranteed for an appropriate space at your place of abode.`,
  },
  {
    title: `<h3>Solar Panel Installation & Maintenance<h3>`,
    description: `Reduce your electricity bills with our <a href="https://karsaaz.app/solar-panel-service" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">solar services </a>. To make that change to renewable energy, we provide solar panel installation, repair, and maintenance.`,
  },
  {
    title: `<h2>Home Construction & Renovation</h2>`,
    description: `Planning to build or renovate your home? Our <a href="https://www.karsaaz.app/home-construction-services" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">home builder service </a> covers everything from foundation to finishing, ensuring top-quality construction tailored to your needs.`,
  },
  {
    title: `<h2>Car Repairs at Your Convenience</h2>`,
    description: `Car troubles? Our <a href="https://www.karsaaz.app/car-maintenance-services" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">car repair services </a> bring skilled mechanics to your doorstep for routine maintenance, oil changes, and urgent repairs.`,
  },
  {
    title: `<h2>Tailoring & Beauty Services at Home</h2>`,
    description: `Get professional tailoring and <a href="https://www.karsaaz.app/beauty-personal-care" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">beauty services </a> right at your doorstep. For any kind of alteration <a href="https://www.karsaaz.app/tailoring" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">tailor services </a> or just a makeover like from any high street salon, our experts are available with just a call from you.`,
  },
  {
    title: `<h2>At-Home Beauty Services</h2>`,
    description: `Why step out into a <a href="https://karsaaz.app/beauty-salon-at-home-services" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">beauty salon </a> when you can have better pampering right in your own home? With Karsaaz at-home <a href="https://karsaaz.app/beauty-salon-deals" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">beauty</a> services, we bring you salon-like professional pampering without the hassle of traffic or waiting in lines. Be it a Janssen facial, a <a href="https://karsaaz.app/Manicure-and-Pedicure-Home-Service" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">
    mani-pedi</a>, waxing, or <a href="https://www.karsaaz.app/hair-treatment-at-home" rel="noopener noreferrer" class="font-bold text-[#07609C] underline cursor-pointer">hair cut for girls</a>, our expert beauticians bring everything along for a premium self-care experience.`,
  },
];
