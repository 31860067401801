import React from "react";

const MapIframe = ({ title, latitude, longitude }) => {
  return (
    <iframe
      title={title}
      width="100%"
      height="200"
      style={{ borderStyle: "none" }}
      loading="lazy"
      allowFullScreen
      referrerPolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed/v1/directions?key=${process.env.REACT_APP_GOOGLE_API_KEY}&origin=${latitude},${longitude}&destination=${latitude},${longitude}&zoom=14`}
    ></iframe>
  );
};

export default MapIframe;
