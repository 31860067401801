import React from "react";
import { Link } from "react-router-dom";

const SettingFooter = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="w-full">
        <div className="w-[90%] mx-auto font-Trueno ">
          <div className="flex justify-between items-center py-7 font-semibold text-[14px] leading-[16.44px]">
            <h1>Copyright © {currentYear} Karsaaz Limited</h1>
            <div className="hidden md:flex gap-2">
              <Link to="/privacy-policy">
                <button>Privacy Policy</button>
              </Link>
              |
              <Link to="/karsaaz-terms-and-conditions">
                <button>Terms and Conditions</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingFooter;
