import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import BlogsSkeleton from "views/loader/BlogsSkeleton";
import Pagination from "lib/Pagination";
import { useAll_blogs, useBlogs_by_category } from "hooks/useUser";

const LatestBlogs = ({
  blog_category_id,
  postsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const navigate = useNavigate();
  const { mutate } = useBlogs_by_category();
  const { data, isLoading } = useAll_blogs();

  // Get current page
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data?.data?.slice(indexOfFirstPost, indexOfLastPost);

  // Change pagination
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleClick = (direction) => {
    let newStep = currentPage;
    direction === "next" ? newStep++ : newStep--;
    newStep > 0 && newStep <= data?.data?.length && setCurrentPage(newStep);
  };

  useEffect(() => {
    const payload = {
      blog_category_id: blog_category_id,
    };
    mutate(payload);
  }, [blog_category_id]);

  const handleBlogsDetailPage = (item) => {
    navigate(`/blogs/${item?.slug}`, {
      state: {
        blogs_detail_data: item?.slug,
      },
    });
  };
  return (
    <>
      <div className="w-full max-w-[800px] font-Trueno">
        <div className="flex flex-col gap-3">
          <h2 className="font-extrabold text-2xl md:text-4xl mb-4 md:mb-7">
            Latest Blogs
          </h2>
          <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-8">
            {isLoading
              ? Array(3)
                  .fill()
                  .map((_, index) => (
                    <div key={index}>
                      <BlogsSkeleton key={index} />
                    </div>
                  ))
              : currentPosts?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      handleBlogsDetailPage(item);
                    }}
                    className="border border-[#DFDFDF] p-1 rounded-2xl w-full max-w-[413px] cursor-pointer"
                  >
                    <div className="relative">
                      <img
                        src={item?.banner_image}
                        alt={item?.banner_image_alt}
                        className="w-full md:w-[402.15px] h-full md:h-[307px] rounded-2xl"
                      />
                      <span className="px-3 md:px-4 py-1 bg-[#0973BA] font-semibold text-sm md:text-base text-white w-fit rounded-2xl absolute top-3 left-3">
                        {item?.blog_category}
                      </span>
                    </div>
                    <div className="flex flex-col gap-2 w-full p-4">
                      <h4 className="font-extrabold text-lg md:text-[22px] leading-[26.82px] capitalize">
                        {item?.title?.length > 50
                          ? item?.title.substring(0, 50) + "...."
                          : item?.title}
                      </h4>
                      <p className="font-normal text-sm md:text-base">
                        {item?.description?.length > 150
                          ? item?.description.substring(0, 150) + "...."
                          : item?.description}
                      </p>
                      <div className="w-full flex justify-between items-center">
                        <Link
                          to={{
                            pathname: `/blogs/${item?.slug}`,
                            state: { blogs_detail_data: item?.slug },
                          }}
                          className="px-3 md:px-4 py-1 bg-[#0973BA] font-semibold text-sm md:text-base text-white w-fit rounded-[50px]"
                        >
                          Read More
                        </Link>
                        <p className="font-normal text-xs">{item?.posted_on}</p>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
          {data?.success === false ? (
            <div className="flex justify-center items-center my-20">
              <h2 className="font-extrabold text-2xl">No Details Found</h2>
            </div>
          ) : null}

          <div
            className={`my-10 ${data?.data?.length >= 7 ? "block" : "hidden"}`}
          >
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={data?.data?.length}
              paginate={paginate}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              handleClick={handleClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestBlogs;
