import { useSearch, useSearch_all } from "hooks/useUser";
import React, { useState, useEffect, useRef } from "react";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const SearchBar = () => {
  const [query, setQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const { data: search_data, mutate: search_mutate, isLoading } = useSearch();
  const { data: search_all_data, mutate: search_all_mutate } = useSearch_all();

  const navigate = useNavigate();
  const inputRef = useRef(null);

  const handleSearchRequest = () => {
    if (query === "") {
      return;
    } else {
      const payload = {
        key: query,
      };
      search_mutate(payload);
    }
  };

  useEffect(() => {
    handleSearchRequest();
  }, [query]);

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setShowSuggestions(false);

    const payload = {
      key: suggestion,
    };
    search_all_mutate(payload);
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) => Math.max(prevIndex - 1, -1));
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) => Math.min(prevIndex + 1, search_data?.data?.length - 1));
    } else if (event.key === "Enter") {
      if (selectedSuggestionIndex >= 0 && selectedSuggestionIndex < search_data?.data?.length) {
        event.preventDefault();
        handleSuggestionClick(search_data.data[selectedSuggestionIndex]?.sub_category_name);
      } else if (query === "") {
        return;
      } else {
        handleClick();
      }
    }
  };

  const handleClick = () => {
    if (search_all_data === undefined) {
      return;
    } else {
      navigate(`/search/${search_all_data?.data?.result?.sub_category_slug}`, {
        state: {
          search_all_data,
        },
      });
    }
  };

  if (search_all_data?.success === true) {
    handleClick();
  }

  return (
    <>
      <div className="relative font-Trueno h-full">
        <BsSearch
          type="button"
          color="black"
          className="w-[15.83px] h-[15.83px] absolute inset-y-0 right-4  border-left top-[18px] cursor-pointer"
        />
        <input
          type="search"
          value={query}
          onChange={(event) => {
            setQuery(event.target.value);
            setShowSuggestions(true);
          }}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          placeholder="Search the service you need! "
          className="border rounded-[15px] border-[#DFDFDF] outline-none w-full h-[52px] py-3 pl-5 md:pl-9 pr-11 placeholder:not-italic placeholder:text-4 placeholder:leading-4  placeholder:text-base text-black"
        />

        {query && showSuggestions && (
          <ul className="flex flex-col gap-2 absolute z-10 transition-all duration-500 ease-in  bg-white rounded-xl shadow-lg py-2 px-1 md:px-4 mt-1 w-full max-h-80 overflow-y-auto">
            {isLoading ? (
              <p className="text-black px-2 md:px-4 py-2 font-normal text-4 leading-4 text-base">Searching .... </p>
            ) : search_data?.data?.length ? (
              search_data.data.map((item, index) => (
                <li
                  key={index}
                  className={`${index === selectedSuggestionIndex
                      ? "bg-[#0973BA] rounded-lg text-white"
                      : "hover:bg-[#0973BA] hover:rounded-lg hover:text-white"
                    } text-black cursor-pointer px-4 py-2 font-normal text-4 leading-4 text-base`}
                  onClick={() => handleSuggestionClick(item?.sub_category_name)}
                >
                  {item?.sub_category_name}
                </li>
              ))
            ) : (
              <p className="text-black px-4 py-2 font-normal text-4 leading-4 text-base">No results found</p>
            )}
          </ul>
        )}
      </div>
    </>
  );
};

export default SearchBar;
