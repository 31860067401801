import { TfiHeadphoneAlt } from "react-icons/tfi";
import {
  MdOutlineWindow,
  MdOutlineDateRange,
  MdLocationOn,
} from "react-icons/md";
import { TfiBag } from "react-icons/tfi";
import {
  AiFillExclamationCircle,
  AiFillTag,
  AiOutlineStar,
} from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { ImPriceTag } from "react-icons/im";
import { HiLocationMarker } from "react-icons/hi";
import BookingNavbar from "views/partials/navbar/BookingNavbar";
import {
  useAssign_request,
  useBids,
  useEnv_verification_values,
  useProvider_detail,
  useProvider_profile,
  useRequest_rating,
  useSingle_request,
} from "hooks/useUser";
import SettingFooter from "../settingsPage/SettingFooter";
import { useLocation, useNavigate } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import { MdVerifiedUser } from "react-icons/md";
import { useEffect, useState } from "react";
import OfferDetails from "./OfferDetails";
import ProfessionalDetails from "./ProfessionalDetails";
import { BsStarFill } from "react-icons/bs";
import { BsStar } from "react-icons/bs";
import { showErrorToast } from "store/features/toastSlice";
import { useDispatch } from "react-redux";
import PaymentConfirmation from "views/paymob/PaymentConfirmation";
import JobCompleteThankYouPage from "views/cashback/JobCompleteThankYouPage";
import MapIframe from "common/MapIframe";

const JobPage = () => {
  const [data, setData] = useState([]);
  const [showOfferDetails, setShowOfferDetails] = useState(false);
  const [offerDetails, setOfferDetails] = useState();
  const [providerDetails, setProviderDetails] = useState();
  const [showProviderDetails, setShowProviderDetails] = useState(false);
  const [loadingItemId, setLoadingItemId] = useState(null);
  const [showJobCompleteThankyouPage, setShowJobCompleteThankyouPage] =
    useState(false);

  window.onpopstate = null;
  window.onbeforeunload = null;

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { provider_id } = location.state || {};

  const request_id = localStorage.getItem("request_id");

  const { data: env_verification_values } = useEnv_verification_values();
  const { data: bids, isLoading: bids_isLoading } = useBids({ request_id });
  const { data: provider_profile_data, mutate: provider_profile_mutate } =
    useProvider_profile();
  const {
    mutate: mutate_provider_details,
    data: provider_detail,
    isLoading,
  } = useProvider_detail();
  const {
    mutate,
    data: assign_request_data,
    isLoading: assign_request_isLoading,
  } = useAssign_request();
  const {
    data: single_request,
    isLoading: single_request_isLoading,
    refetch,
  } = useSingle_request({ request_id });
  const {
    mutate: request_rating_mutate,
    data: request_rating_data,
    isLoading: request_rating_isLoading,
  } = useRequest_rating();

  const [rating, setRating] = useState(0);

  const [comment, setComment] = useState("");

  const handleChangeRating = (ratingValue) => {
    setRating(ratingValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      rating: rating,
      comment: comment,
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
      request_id: single_request?.data?.request_id,
    };
    request_rating_mutate(payload);
    setComment("");
    setRating(0);
  };

  const handleChangeComment = (event) => {
    setComment(event.target.value);
  };

  useEffect(() => {
    const handleMutation = (single_request) => {
      const payload = {
        id: single_request?.data?.provider_id,
        user_id: single_request?.data?.id,
      };
      mutate_provider_details(payload);
    };

    if (single_request) {
      handleMutation(single_request);
    }
  }, [single_request]);

  useEffect(() => {
    const provider_profile_payload = {
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
      request_id: request_id,

      provider_id: provider_id,
    };

    provider_profile_mutate(provider_profile_payload);

    const interval = setInterval(() => {
      if (
        single_request?.data?.status === 8 ||
        single_request?.data?.status === 9
      ) {
        return null;
      } else {
        refetch();
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [provider_id, request_id, single_request]);

  const handleClick = (single_request) => {
    navigate(`/edit-booking`, {
      state: {
        request_id: single_request?.data?.request_id,
        description: single_request?.data?.description,
        request_date: single_request?.data?.request_date,
        s_address: single_request?.data?.s_address,
        price_per_hour: single_request?.data?.price_per_hour,
        name: single_request?.data?.name,
      },
    });
  };

  const handleAssignRequest = (item) => {
    setLoadingItemId(item?.request_meta_id);
    const payload = {
      request_id: item?.request_id,
      request_meta_id: item?.request_meta_id,
      price_per_hour: item?.bid_amount,
    };

    mutate(payload);
  };

  useEffect(() => {
    if (assign_request_data?.success === true) {
      refetch();
      window.location.reload();
    }

    if (request_rating_data?.success === true) {
      refetch();
      // request_rating_isLoading();
      setShowJobCompleteThankyouPage(true);
    }
  }, [assign_request_data, request_rating_data]);

  const showProvider = (provider_detail) => {
    setShowProviderDetails(true);
    setProviderDetails({
      avg_rating: provider_detail?.data?.avg_rating,
      business_name: provider_detail?.data?.business_name,
      police_verified: provider_detail?.data?.police_verified,
      provider_name: provider_detail?.data?.provider_name,
      provider_picture: provider_detail?.data?.provider_picture,
      ratings: provider_detail?.data?.ratings,
      services: provider_detail?.data?.services,
      working_days: provider_detail?.data?.working_days,
      working_from: provider_detail?.data?.working_from,
      working_to: provider_detail?.data?.working_to,
      experience: provider_detail?.data?.experience,
      education: provider_detail?.data?.education,
      description: provider_detail?.data?.description,
      work_from: provider_detail?.data?.work_from,
      isindividual: provider_detail?.data?.isindividual,
      qualification: provider_detail?.data?.qualification,
      provider_id: provider_detail?.data?.provider_id,
      user_id: provider_detail?.data?.user_id,
      total_projects: provider_profile_data?.data?.ratings.length,
      latitude: provider_detail?.data?.latitude,
      longitude: provider_detail?.data?.longitude,
      address: provider_detail?.data?.address,
      request_id: single_request?.data?.request_id,
      provider_mobile: single_request?.data?.provider_mobile,
      rating_details: provider_detail?.data?.rating_details,
      is_favorite: single_request?.data?.provider_favorite,
    });
  };

  const showOffer = (item) => {
    setShowOfferDetails(true);
    setOfferDetails({
      provider_picture: item?.provider_picture,
      provider_name: item?.provider_name,
      provider_rating: item?.provider_rating,
      bid_amount: item?.bid_amount,
      created: item?.created,
      provider_description: item?.provider_description,
      request_meta_id: item?.request_meta_id,
      request_meta_images: item?.request_meta_images,
      rating: item?.rating,
      assign_request_isLoading: assign_request_isLoading,
    });
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const starElements = [];

    for (let i = 0; i < fullStars; i++) {
      starElements.push(
        <AiFillStar
          key={`full-star-${i}`}
          color="#F9B523"
          height="16.28px"
          width="17.12px"
        />
      );
    }

    if (hasHalfStar) {
      starElements.push(
        <AiFillStar
          key="half-star"
          color="#F9B523"
          height="16.28px"
          width="17.12px"
        />
      );
    }

    const remainingStars = 5 - Math.ceil(rating); // Calculate the remaining empty stars
    for (let i = 0; i < remainingStars; i++) {
      starElements.push(
        <AiOutlineStar
          key={`empty-star-${i}`}
          color="#828282"
          height="16.28px"
          width="17.12px"
        />
      );
    }

    return starElements;
  };

  if (data?.error_code === 104 || data?.error_code === 103) {
    dispatch(showErrorToast("Another user is Logged in on different device"));

    localStorage.removeItem("token");
    localStorage.removeItem("id");

    navigate("/login");
  }

  return (
    <>
      <div className="w-full font-Trueno bg-[#EDF6FC]">
        <BookingNavbar color="#EDF6FC" />
        <div className="w-[90%] mx-auto bg-white rounded-2xl shadow-2xl">
          {/* --------------------- Heading section --------------------- */}

          {single_request_isLoading ? (
            <div className="flex flex-col justify-between w-full bg-[#EDF6FC] drop-shadow-md rounded-t-xl ">
              <div className="p-3 space-y-4">
                <div className="animate-pulse mx-auto w-1/4 h-5 bg-sky-200"></div>
              </div>
            </div>
          ) : (
            <div className="w-full justify-center text-white mt-0 lg:mt-8">
              {single_request?.data?.status === 1 && (
                <div className="bg-[#E88C58] flex items-center gap-2 justify-center font-bold p-3 rounded-t-xl w-full">
                  <h1 className="font-normal text-[14px] leading-[16.8px]  text-center">
                    status:
                  </h1>
                  Posted
                </div>
              )}
              {single_request?.data?.status === 2 && (
                <div className="bg-[#E88C58] flex items-center gap-2 justify-center font-bold p-3 rounded-t-xl w-full">
                  <h1 className="font-normal text-[14px] leading-[16.8px]  text-center">
                    status:
                  </h1>
                  Bidding
                </div>
              )}

              {single_request?.data?.status === 4 && (
                <div className="bg-[#E88C58] flex items-center gap-2 justify-center font-bold p-3 rounded-t-xl w-full">
                  <h1 className="font-normal text-[14px] leading-[16.8px]  text-center">
                    status:
                  </h1>
                  Ongoing
                </div>
              )}
              {single_request?.data?.status === 5 && (
                <div className="bg-[#09BABA] flex items-center gap-2 justify-center font-bold p-3 rounded-t-xl w-full">
                  <h1 className="font-normal text-[14px] leading-[16.8px]  text-center">
                    status:
                  </h1>
                  Payment Pending
                </div>
              )}
              {single_request?.data?.status === 6 && (
                <div className="bg-[#09BABA] flex items-center gap-2 justify-center font-bold p-3 rounded-t-xl w-full">
                  <h1 className="font-normal text-[14px] leading-[16.8px]  text-center">
                    status:
                  </h1>
                  Payment Confirmation
                </div>
              )}
              {single_request?.data?.status === 7 && (
                <div className="bg-[#09BABA] flex items-center gap-2 justify-center font-bold p-3 rounded-t-xl w-full">
                  <h1 className="font-normal text-[14px] leading-[16.8px]  text-center">
                    status:
                  </h1>
                  Waiting for Rating
                </div>
              )}
              {single_request?.data?.status === 8 && (
                <div className="bg-[#09BA70] flex items-center gap-2 justify-center font-bold p-3 rounded-t-xl w-full">
                  <h1 className="font-normal text-[14px] leading-[16.8px]  text-center">
                    status:
                  </h1>
                  Completed
                </div>
              )}
              {single_request?.data?.status === 9 && (
                <div className="bg-[#FF4E4E] flex items-center gap-2 justify-center font-bold p-3 rounded-t-xl w-full">
                  <h1 className="font-normal text-[14px] leading-[16.8px]  text-center">
                    status:
                  </h1>
                  Canceled
                </div>
              )}
            </div>
          )}

          <div className="py-6 px-[18px] md:px-[22px]">
            <div>
              <h1 className="font-semibold text-[16px] md:text-[20px] leading-[24.38px] text-[#0973BA] py-5 border-b border-[#DFDFDF] md:border-none">
                {single_request?.data?.name}
              </h1>
            </div>
            <div className="grid md:flex gap-6 justify-between items-center py-4 md:py-0">
              <h1 className="font-normal text-[14px] md:text-[16px] leading-[19.2px]">
                Buyer:
                <span className="font-semibold text-[14px] md:text-[16px] text-[#0973BA]">
                  {" "}
                  {single_request?.data?.user_name}
                </span>
              </h1>
              <h1 className="font-normal text-[14px] md:text-[16px]  leading-[19.2px]">
                Request ID:{" "}
                <span className="font-semibold text-[14px] md:text-[16px] text-[#0973BA]">
                  {single_request?.data?.request_id}
                </span>
              </h1>

              <div className="grid md:grid-cols-2 lg:grid-col-3 lg:grid-flow-col gap-4">
                <div className="w-full flex gap-2 md:gap-4  items-center border border-[#09BA70] rounded-lg p-3 max-w-[166px]">
                  <TfiHeadphoneAlt
                    height="24.03px"
                    width="24.03px"
                    color="#09BA70"
                  />
                  <a
                    className="full font-normal text-[12px] leading-[14.4px] text-[#09BA70]"
                    href={`tel:${env_verification_values?.data?.karsaaz_mobile}`}
                  >
                    Contact Support
                  </a>
                </div>
                {/* <div className="flex gap-2 md:gap-4 items-center border border-[#0973BA] rounded-lg p-3 max-w-[166px]">
                  <TbFileInvoice height="24.03px" width="24.03px" color="#09BA70" />
                  <button className="font-normal text-[12px] leading-[14.4px] text-[#0973BA] max-w-[166px]">Get Invoice</button>
                </div> */}
                {/* <div className="flex gap-2 justify-center border border-[#FF4E4E] rounded-lg p-3 max-w-[140px]">
                  <button className="font-normal text-[12px] leading-[14.4px] text-[#FF4E4E]">Cancel Job</button>
                </div> */}
                {single_request?.data?.status === 1 ? (
                  <div className="flex gap-2 justify-center border border-[#0973BA] rounded-lg p-3 max-w-[140px]">
                    <button
                      type="button"
                      onClick={() => handleClick(single_request)}
                      className="w-full font-normal text-[12px] leading-[14.4px] text-[#0973BA]"
                    >
                      Edit
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* --------------------- category section --------------------- */}
          <div className="border-t border-[#DFDFDF] px-[18px] md:px-[22px]">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 py-6">
              <div className="flex gap-3 items-center border border-[#DFDFDF] rounded-xl p-2 max-w-[345px] max-h-[79px]">
                <div className="bg-[#FFE7C5] p-4 rounded-lg">
                  <img
                    className="w-[16px] h-[16px]"
                    src={`${process.env.PUBLIC_URL}/images/RequestTypeIcon.png`}
                    alt=""
                  />
                </div>
                <div>
                  <h1 className="font-normal text-[12px] leading-[14.4px]">
                    Request Type
                  </h1>
                  {single_request?.data?.is_quick_book === 1 &&
                  single_request?.data?.is_urgent === 1 &&
                  single_request?.data?.job_type === 2 ? (
                    <h1 className="font-semibold text-[14px] leading-[19.5px]">
                      Direct / Urgent
                    </h1>
                  ) : single_request?.data?.is_quick_book === 0 &&
                    single_request?.data?.is_urgent === 0 &&
                    single_request?.data?.job_type === 2 ? (
                    <h1 className="font-semibold text-[14px] leading-[19.5px]">
                      Bidding
                    </h1>
                  ) : single_request?.data?.is_quick_book === 1 &&
                    single_request?.data?.is_urgent === 0 &&
                    single_request?.data?.job_type === 2 ? (
                    <h1 className="font-semibold text-[14px] leading-[19.5px]">
                      Quickly Book
                    </h1>
                  ) : null}
                </div>
              </div>
              <div className="flex gap-3 items-center border border-[#DFDFDF] rounded-xl p-2 max-w-[345px]">
                <div className="bg-[#239FF9] bg-opacity-[0.15] p-4 rounded-lg">
                  <MdOutlineWindow color="#239FF9" height="30px" width="30px" />
                </div>
                <div>
                  <h1 className="font-normal text-[12px] leading-[14.4px]">
                    Category
                  </h1>
                  <h1 className="font-semibold text-[14px] leading-[19.5px]">
                    {" "}
                    {single_request?.data?.category_name}
                  </h1>
                </div>
              </div>
              <div className="flex gap-3 items-center border border-[#DFDFDF] rounded-xl p-2 max-w-[345px]">
                <div className="bg-[#232CF9] bg-opacity-[0.15] p-4 rounded-lg">
                  <img
                    className="w-[16px] h-[16px]"
                    src={`${process.env.PUBLIC_URL}/images/SubCategoryIcon.png`}
                    alt=""
                  />
                </div>
                <div>
                  <h1 className="font-normal text-[12px] leading-[14.4px]">
                    Sub-Category
                  </h1>
                  <h1 className="font-semibold text-[14px] leading-[19.5px]">
                    {" "}
                    {single_request?.data?.sub_category_name}
                  </h1>
                </div>
              </div>
              <div className="flex gap-3 items-center border border-[#DFDFDF] rounded-xl p-2 max-w-[345px]">
                <div className="bg-[#2AADAD] bg-opacity-[0.15] p-4 rounded-lg">
                  <MdOutlineDateRange
                    color="#2AADAD"
                    height="30px"
                    width="30px"
                  />
                </div>
                <div>
                  <h1 className="font-normal text-[12px] leading-[14.4px]">
                    Date & Time
                  </h1>
                  <h1 className="font-semibold text-[14px] leading-[19.5px]">
                    {single_request?.data?.request_date}
                  </h1>
                </div>
              </div>

              {/* --------------------------------------------- Time Category --------------------------------------------- */}
              {/* <div className="flex gap-3 items-center border border-[#DFDFDF] rounded-xl p-2 max-w-[345px]">
                <div className="bg-[#09BA5A] bg-opacity-[0.15] p-4 rounded-lg">
                  <BiTime color="#09BA5A" height="30px" width="30px" />
                </div>
                <div>
                  <h1 className="font-normal text-[12px] leading-[14.4px]">Time</h1>
                  <h1 className="font-semibold text-[14px] leading-[19.5px]">{single_request?.data?.request_date}</h1>
                </div>
              </div> */}
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].includes(
                single_request?.data?.status
              ) && (
                <div className="flex gap-3 items-center border border-[#DFDFDF] rounded-xl p-2 max-w-[345px]">
                  <div className="bg-[#0973BA] bg-opacity-[0.15] p-4 rounded-lg">
                    <AiFillTag className="w-[16px] h-[16px]" />
                  </div>
                  <div>
                    <h1 className="font-normal text-[12px] leading-[14.4px]">
                      Price
                    </h1>
                    <h1 className="flex gap-3 justify-between items-center font-semibold text-[14px] leading-[19.5px]">
                      RS. {single_request?.data?.price_per_hour}
                      <span className="flex items-center gap-[9.12px] text-[#09BA70] font-bold text-[12px] leading-[14.4px]">
                        <ImPriceTag height="11.27px" width="11.27px" />
                        {single_request?.data?.price_type}
                        <AiFillExclamationCircle color="#0973BA" />
                      </span>
                    </h1>
                  </div>
                </div>
              )}
              <div className="flex gap-3 items-center border border-[#DFDFDF] rounded-xl p-2 w-fit md:min-w-full">
                <div className="bg-[#0973BA] bg-opacity-[0.15] p-4 rounded-lg">
                  <HiLocationMarker
                    color="#0973BA"
                    height="30px"
                    width="30px"
                  />
                </div>
                <div>
                  <h1 className="font-normal text-[12px] leading-[14.4px]">
                    location
                  </h1>
                  <h1 className="font-semibold text-[14px] leading-[19.5px]">
                    {single_request?.data?.s_address}
                  </h1>
                </div>
              </div>
            </div>

            {/* --------------------- Payment Confirmation section --------------------- */}

            {single_request?.data?.status === 5 ||
            single_request?.data?.status === 6 ? (
              <PaymentConfirmation
                single_request={single_request}
                refetch={refetch}
              />
            ) : null}
            {/* --------------------- Provider Location section --------------------- */}
            <div>
              <h1 className="font-normal text-[18px] leading-[21.6px] my-6">
                Provider Locations
              </h1>
              {single_request_isLoading ? (
                <div>
                  <div className="flex flex-col gap-4 justify-between w-[231px] bg-white drop-shadow-md rounded-2xl p-3 ">
                    <div className="p-2 space-y-1">
                      <div className="flex flex-col gap-4 items-center">
                        <div className="animate-pulse rounded-sm w-[150px] h-[13px] bg-slate-200"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-fit h-fit border border-[#DFDFDF] rounded-xl py-8 px-6">
                  {single_request?.data?.provider_started?.length !== 0 ? (
                    <div className="flex flex-col gap-2">
                      <h2 className="font-semibold text-[14px] leading-[19.5px] cursor-pointer">
                        {" "}
                        Provider Started Location:{" "}
                      </h2>

                      <p className="font-normal text-[12px] leading-[14.4px]">
                        {single_request?.data?.provider_started?.address}
                      </p>
                      <MapIframe
                        title="Provider Started Location"
                        latitude={
                          single_request?.data?.provider_started?.latitude
                        }
                        longitude={
                          single_request?.data?.provider_started?.longitude
                        }
                      />
                    </div>
                  ) : null}

                  {single_request?.data?.provider_arrived?.length !== 0 ? (
                    <div className="flex flex-col gap-2">
                      <h2 className="font-semibold text-[14px] leading-[19.5px] cursor-pointer">
                        Provider Arrived Location:
                      </h2>

                      <p className="font-normal text-[12px] leading-[14.4px]">
                        {single_request?.data?.provider_arrived?.address}
                      </p>
                      <MapIframe
                        title="Provider Arrived Location"
                        latitude={
                          single_request?.data?.provider_arrived?.latitude
                        }
                        longitude={
                          single_request?.data?.provider_arrived?.longitude
                        }
                      />
                    </div>
                  ) : null}

                  {single_request?.data?.provider_service_started?.length !==
                  0 ? (
                    <div className="flex flex-col gap-2">
                      <h2 className="font-semibold text-[14px] leading-[19.5px] cursor-pointer">
                        Provider Service Started Location:
                      </h2>

                      <p className="font-normal text-[12px] leading-[14.4px]">
                        {
                          single_request?.data?.provider_service_started
                            ?.address
                        }
                      </p>

                      <MapIframe
                        title="Provider Service Started Location"
                        latitude={
                          single_request?.data?.provider_service_started
                            ?.latitude
                        }
                        longitude={
                          single_request?.data?.provider_service_started
                            ?.longitude
                        }
                      />
                    </div>
                  ) : null}

                  {single_request?.data?.provider_service_completed?.length !==
                  0 ? (
                    <div className="flex flex-col gap-2">
                      <h2 className="font-semibold text-[14px] leading-[19.5px] cursor-pointer">
                        Provider Service Completed Location:
                      </h2>

                      <p className="font-normal text-[12px] leading-[14.4px]">
                        {
                          single_request?.data?.provider_service_completed
                            ?.address
                        }
                      </p>

                      <MapIframe
                        title="Provider Service Started Location"
                        latitude={
                          single_request?.data?.provider_service_completed
                            ?.latitude
                        }
                        longitude={
                          single_request?.data?.provider_service_completed
                            ?.longitude
                        }
                      />
                    </div>
                  ) : null}
                  {single_request?.data?.provider_started?.length === 0 &&
                  single_request?.data?.provider_arrived?.length === 0 &&
                  single_request?.data?.provider_service_started?.length ===
                    0 &&
                  single_request?.data?.provider_service_completed?.length ===
                    0 ? (
                    <div className="flex items-center gap-2">
                      <h2 className="font-normal text-[12px] leading-[19px] px-3">
                        No Location Found
                      </h2>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
            {/* --------------------- Detailed section --------------------- */}
            <div className="grid lg:grid-flow-col lg:grid-col-2 gap-3 md:gap-5  my-6 ">
              <div className="flex flex-col gap-4 w-full">
                <div className="w-full grid gap-4 ">
                  <h1 className="font-normal text-[18px] leading-[21.6px]">
                    Description
                  </h1>
                  <div className="w-full max-w-[716px] h-[213px] border border-[#DFDFDF] rounded-xl py-8 px-6">
                    <p>{single_request?.data?.description}</p>
                  </div>
                </div>
                <div className="max-w-[716px] grid gap-4">
                  <h1 className="font-normal text-[18px] leading-[21.6px]">
                    Images
                  </h1>

                  <div className="w-full grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4 items-center">
                    {single_request?.data?.user_request_images?.map(
                      (item, index) => (
                        <div key={index}>
                          <img
                            src={item?.image}
                            alt=""
                            className="w-[90px] md:w-[115.27px] h-[90px] md:h-[115.27px] rounded-2xl object-cover"
                          />
                        </div>
                      )
                    )}

                    {single_request?.data?.user_request_images?.length ===
                      0 && (
                      <div className="w-full h-[115.27px] rounded-2xl border border-[#DFDFDF] flex justify-center items-center">
                        <p className="w-full  font-normal text-[12px] leading-[19px] px-3">
                          {" "}
                          No Details Found
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* --------------------- Offers Received section --------------------- */}
              {[4, 5, 6, 7, 8, 9].includes(
                single_request?.data?.status
              ) ? null : (
                <div>
                  {bids?.data?.length > 0 ? (
                    <div className="w-full flex flex-col gap-3">
                      <h1 className="font-normal text-[18px] leading-[21.6px]">
                        Offers Received
                      </h1>
                      {bids_isLoading
                        ? Array(1)
                            .fill()
                            .map((_, index) => (
                              <div
                                key={index}
                                class="md:max-w-[508px] bg-white p-2 sm:p-4 sm:h-52 rounded-2xl border border-[#DFDFDF] shadow-lg flex flex-col sm:flex-row gap-5 select-none mt-9"
                              >
                                <div class="md:w-[136px] w-[106.54px] md:h-[116px] h-[106.54px] rounded-xl bg-gray-200 animate-pulse"></div>
                                <div class="flex flex-col flex-1 gap-5 sm:p-2">
                                  <div class="flex py-5 flex-col gap-3">
                                    <div class="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
                                    <div class="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
                                    <div class="bg-gray-200 w-full animate-pulse h-3 rounded-2xl"></div>
                                    <div class="mt-auto flex gap-3">
                                      <div class="bg-gray-200 w-20 h-8 animate-pulse rounded-full"></div>
                                      <div class="bg-gray-200 w-20 h-8 animate-pulse rounded-full"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                        : bids?.data?.map((item, index) => (
                            <div
                              key={index}
                              className="flex flex-col gap-4 w-full"
                            >
                              <div className="hidden">
                                {item?.request_meta_id} {item?.offer_amount}
                              </div>
                              <div className="grid md:flex gap-4 items-center border rounded-xl border-[#DFDFDF] p-2">
                                <img
                                  // src={`${process.env.PUBLIC_URL}/images/FavoriteProviderIcon.png`}
                                  src={item?.provider_picture}
                                  className="md:w-[136px] w-[106.54px] md:h-[116px] h-[106.54px] rounded-lg object-cover"
                                  alt=""
                                />
                                <div className="w-full flex flex-col gap-1">
                                  <div className="flex gap-1 justify-between items-center">
                                    <div className="flex gap-1  items-center">
                                      <h1 className="font-semibold text-[18px] leading-[21.94px]">
                                        {item?.provider_name}
                                      </h1>
                                      <AiFillStar
                                        color="#F9B523"
                                        height="16.28px"
                                        width="17.12px"
                                      />
                                      <span className="font-normal text-[14px] leading-[16.8px]">
                                        {item?.rating &&
                                          parseFloat(item?.rating).toFixed(1)}
                                      </span>
                                      {/* <label className="font-normal text-[14px] leading-[16.8px] text-[#979797]">
    {single_request?.data?.provider_avg_rating}
  </label> */}
                                    </div>
                                    <label className="bg-[#E8A931] text-white font-normal text-sm py-1 px-3 rounded-md ">
                                      {" "}
                                      New{" "}
                                    </label>
                                  </div>
                                  <div className="flex gap-1 md:gap-2 items-center">
                                    <span className="flex gap-1 justify-start md:justify-between items-center text-[#09BA70] font-normal text-[14px] leading-[14px] px-2 h-[25px]">
                                      <MdVerifiedUser
                                        height="16.5px"
                                        width="13.5px"
                                        color="#09BA70"
                                      />
                                      Verified
                                    </span>
                                    {/* <label className="font-semibold text-[12px] leading-[14.4px]">| {item?.distance}km </label> */}
                                  </div>
                                  <div className="flex gap-2 items-center">
                                    <label className="font-normal text-[14px] leading-[16.8px] text-[#979797]">
                                      Price
                                    </label>
                                    <h1 className="font-bold text-[18px] md:text-[22px] leading-[26.4px] text-[#0973BA]">
                                      Rs.{item?.bid_amount}
                                    </h1>
                                  </div>
                                  <div className="flex flex-col-reverse md:flex md:flex-row justify-between items-start md:items-center gap-3 ">
                                    <div className="flex gap-1 items-center">
                                      <button
                                        onClick={() => showOffer(item)}
                                        className="w-[117px] h-[34px] font-medium md:font-semibold text-[12px] md:text-[14px] leading-[17.07px] text-center border border-[#0973BA] rounded-lg text-[#0973BA]"
                                      >
                                        See details
                                      </button>
                                      {showOfferDetails && (
                                        <OfferDetails
                                          offer={offerDetails}
                                          assign_request_isLoading={
                                            assign_request_isLoading
                                          }
                                          onClose={() =>
                                            setShowOfferDetails(false)
                                          }
                                          onAssigned={() => {
                                            handleAssignRequest(item);
                                          }}
                                        />
                                      )}

                                      <button
                                        disabled={assign_request_isLoading}
                                        onClick={() => {
                                          handleAssignRequest(item);
                                          // handleProviderDetails(single_request);
                                        }}
                                        className="w-[117px] h-[34px] font-medium md:font-semibold text-[14px] leading-[17.07px] text-white text-center rounded-lg bg-[#0973BA]"
                                      >
                                        {assign_request_isLoading &&
                                        item?.request_meta_id ===
                                          loadingItemId ? (
                                          <div
                                            className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                            role="status"
                                          >
                                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                              Loading...
                                            </span>
                                          </div>
                                        ) : (
                                          "Accept"
                                        )}
                                      </button>
                                    </div>
                                    <p className="font-normal text-[12px] md:text-[14px] leading-[16.8px]">
                                      {item?.created} Ago
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                    </div>
                  ) : null}
                </div>
              )}

              {/* --------------------- Assigned to  section --------------------- */}

              {[4, 5, 6, 7, 8, 9].includes(single_request?.data?.status) && (
                <div className="flex flex-col gap-4">
                  <h1 className="font-normal text-[18px] leading-[21.6px]">
                    Assigned to
                  </h1>

                  {isLoading ? (
                    Array(1)
                      .fill()
                      .map((_, index) => (
                        <div
                          key={index}
                          className="flex flex-col justify-between w-full md:w-[550px] bg-[#EDF6FC] drop-shadow-md rounded-2xl p-3"
                        >
                          <div className="flex gap-3 items-center">
                            <div className="animate-pulse w-[88px] h-[88px] rounded-full bg-slate-200"></div>

                            <div className="animate-pulse w-1/3 h-3 bg-sky-200"></div>
                          </div>
                          <div className="p-3 space-y-4">
                            <div className="flex flex-col space-y-4">
                              <div className="animate-pulse w-1/3 h-5 bg-sky-200"></div>
                              <div className="animate-pulse w-1/3 h-5 bg-sky-200"></div>
                            </div>
                          </div>
                        </div>
                      ))
                  ) : (
                    <div className="border rounded-xl border-[#DFDFDF] p-3 md:p-8">
                      <div className="w-full grid md:flex gap-4 items-center justify-between">
                        <div className="flex gap-4 items-center">
                          {single_request?.data?.provider_picture === "" ||
                          single_request?.data?.provider_picture ===
                            "https://stage.karsaaz.app/placeholder.png" ? (
                            <img
                              src={`${process.env.PUBLIC_URL}/images/EditProfileIcon.png`}
                              alt=""
                              className="rounded-full h-[121px] w-[121px] object-cover"
                            />
                          ) : (
                            <img
                              src={single_request?.data?.provider_picture}
                              className="w-[88px] h-[88px] rounded-full"
                              alt="images"
                            />
                          )}
                          <div className="flex flex-col gap-1">
                            <h1 className="font-semibold text-sm md:text-[16px] leading-[19.5px]">
                              {single_request?.data?.provider_name}
                            </h1>
                            <div className="grid md:flex items-center gap-1">
                              <span className="flex items-center gap-1">
                                {provider_detail?.data?.avg_rating &&
                                  renderStars(
                                    parseFloat(
                                      provider_detail?.data?.avg_rating
                                    )
                                  )}
                              </span>
                              <span className="font-normal text-[14px] leading-[16.8px]">
                                {single_request?.buyer_rating.rating &&
                                  parseFloat(
                                    provider_detail?.data?.avg_rating
                                  ).toFixed(1)}

                                <span className="ml-2 font-normal text-sm text-[#979797]">
                                  ({provider_detail?.data?.ratings?.length}{" "}
                                  Reviews)
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div>
                          {showProviderDetails && (
                            <ProfessionalDetails
                              details={providerDetails}
                              onClose={() => setShowProviderDetails(false)}
                              setShowProviderDetails={setShowProviderDetails}
                            />
                          )}
                          <button
                            onClick={() => {
                              showProvider(provider_detail);
                            }}
                            className="font-bold text-sm md:text-[16px] leading-[19.2px] text-[#0973BA]"
                          >
                            View Profile
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-col gap-7 pt-3 md:pt-8">
                        <div className="grid gap-2 sm:flex sm:gap-4 justify-between items-center pb-6 border-b">
                          <span className="flex items-center gap-0 md:gap-2">
                            <MdLocationOn
                              width="13.5px"
                              height="16.3px"
                              color="#0973BA"
                            />
                            <p className="font-normal text-xs md:text-[14px] leading-[14.4px]">
                              Location
                            </p>
                          </span>
                          <p className="font-semibold text-[12px] leading-[14.63px]">
                            {provider_detail?.data?.address}
                          </p>
                        </div>

                        {single_request?.data?.provider_mobile !== "" ? (
                          <div className="flex gap-4 justify-between items-center pb-6 border-b">
                            <span className="flex items-center gap-2">
                              <BsTelephone
                                width="13.5px"
                                height="16.3px"
                                color="#0973BA"
                              />
                              <p className="font-normal text-xs md:text-[14px] leading-[14.4px]">
                                Phone Number
                              </p>
                            </span>
                            <p className="font-semibold text-[12px] leading-[14.63px]">
                              {single_request?.data?.provider_mobile}
                            </p>
                          </div>
                        ) : null}
                        <div className="flex gap-4 justify-between items-center pb-5 md:pb-0">
                          <span className="flex items-center gap-2">
                            <TfiBag
                              width="13.5px"
                              height="16.3px"
                              color="#0973BA"
                            />
                            <p className="font-normal text-xs md:text-[14px] leading-[14.4px]">
                              Completed Jobs
                            </p>
                          </span>
                          <p className="font-semibold text-[12px] leading-[14.63px]">
                            {provider_profile_data?.data?.ratings.length}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            {/* --------------------------------- Reviews and Rating for Provider --------------------------------- */}
            {[7].includes(single_request?.data?.status) ? (
              <div className="max-w-[616px]">
                <form onSubmit={handleSubmit}>
                  <h1 className="font-normal text-[18px] leading-[21.6px]">
                    Rating:
                  </h1>

                  <div className="flex flex-col gap-3 mt-2">
                    <div className="grid md:flex gap-2">
                      <p className="mr-2">Rate this provider </p>
                      <div className="flex gap-2">
                        {[1, 2, 3, 4, 5].map((star) => (
                          <button
                            key={star}
                            type="button"
                            className="focus:outline-none"
                            onClick={() => handleChangeRating(star)}
                          >
                            {star <= rating ? (
                              <BsStarFill
                                size={18}
                                className="text-yellow-500"
                              />
                            ) : (
                              <BsStar size={18} className="text-gray-400" />
                            )}
                          </button>
                        ))}
                      </div>
                    </div>
                    <textarea
                      className="w-full h-32 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Write your comment..."
                      value={comment}
                      onChange={handleChangeComment}
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                  >
                    {request_rating_isLoading ? (
                      <div
                        className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                      >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                          Loading...
                        </span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </form>
              </div>
            ) : null}

            {showJobCompleteThankyouPage && (
              <JobCompleteThankYouPage
                single_request={single_request}
                setShowJobCompleteThankyouPage={setShowJobCompleteThankyouPage}
              />
            )}
          </div>

          <div className="pt-20">
            <SettingFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default JobPage;
