import { Collaborators } from "hooks/useUser";
import { Collaborators_settings } from "helpers/constants";
import Slider from "react-slick";

const collaborators = () => {
  const { data, isLoading } = Collaborators();

  return (
    <>
      <div className="w-[70%] mx-auto my-20">
        <h2 className="font-Inter font-bold text-3xl md:text-[36px] leading-[43.2px] text-center">
          Our B2B Collaborations
        </h2>
        <div className="w-full mt-14 ">
          <Slider {...Collaborators_settings}>
            {isLoading
              ? Array(3)
                  .fill()
                  .map((_, index) => (
                    <div key={index} className="w-fit my-6">
                      <div className="animate-pulse w-full h-[60px] md:w-[200px] md:h-[80px] rounded-xl bg-slate-200"></div>
                    </div>
                  ))
              : data?.data?.map((item, index) => (
                  <div key={index} className="w-full mx-auto">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={item?.link}
                      className="outline-none"
                    >
                      <img
                        src={item?.logo}
                        alt=""
                        className="w-[120px] md:w-[220px] "
                      />
                    </a>
                  </div>
                ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default collaborators;
