import React from 'react'

const YoutubeIframe = ({className, width, height, src, title}) => {
  return (
    <>
        <iframe
        className={className}
        src={src}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        loading="lazy"
        ></iframe>
    </>
  )
}

export default YoutubeIframe