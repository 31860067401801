import React from "react";
import YoutubeIframe from "./YoutubeIframe";

const CorporateClientFeedback = () => {
  return (
    <>
      <div className="w-[90%] mx-auto flex flex-col gap-3 items-center my-20 p-2 md:p-5 border rounded-3xl">
        <h3 className="font-Inter font-bold text-3xl md:text-[36px] leading-[43.2px] text-center capitalize">
          Corporate Client Feedback
        </h3>
        <p className="font-normal text-base md:text-[18px] leading-[31px] mt-[23px] text-center ">
          Transforming Spaces with Karsaaz! From outdated to extraordinary!
        </p>

        <YoutubeIframe
          className="rounded-[10px] w-full h-[600px] mt-[23px]"
          src="https://www.youtube.com/embed/piWKVcmi9-o?si=4DGD2w6w9ENzmByN"
          title="YouTube video player"
        />
      </div>
    </>
  );
};

export default CorporateClientFeedback;
