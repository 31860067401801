import AppStoreButton from "theme/buttons/AppStoreButton";
import PlayStoreButton from "theme/buttons/PlayStoreButton";
import CustomerChooseIcons from "common/CustomerChooseIcons";
import { useHomepage_videos } from "hooks/useUser";
import YoutubeIframe from "./YoutubeIframe";

const CustomerChoosesUs = (props) => {
  const { data, isLoading } = useHomepage_videos();

  const CustomerChooseData = [
    {
      id: 1,
      heading: "Police Verified Experts",
      picture: `${process.env.PUBLIC_URL}/images/VerifiedIcon.svg`,
    },

    {
      id: 2,
      heading: "Urgent Booking & Get Offers ",
      picture: `${process.env.PUBLIC_URL}/images/UrgentBookingIcon.svg`,
    },

    {
      id: 3,
      heading: "15 Days Service Warranty",
      picture: `${process.env.PUBLIC_URL}/images/ServiceWarrantyIcon.svg`,
    },

    {
      id: 4,
      heading: "Lowest Market Price Guaranteed",
      picture: `${process.env.PUBLIC_URL}/images/MarketPriceIcon.svg`,
    },

    {
      id: 5,
      heading: "Service Quality Guaranteed",
      picture: `${process.env.PUBLIC_URL}/images/ServiceQualityIcon.svg`,
    },

    {
      id: 6,
      heading: "User Friendly",
      picture: `${process.env.PUBLIC_URL}/images/UserFriendlyIcon.svg`,
    },

    {
      id: 7,
      heading: "+100k User",
      picture: `${process.env.PUBLIC_URL}/images/100k.png`,
    },

    {
      id: 8,
      heading: "300+ services ",
      picture: `${process.env.PUBLIC_URL}/images/300-services.png`,
    },

    {
      id: 9,
      heading: "Safe & Track Service ",
      picture: `${process.env.PUBLIC_URL}/images/safe-track-services.png`,
    },

    {
      id: 10,
      heading: "Responsible Customer Service",
      picture: `${process.env.PUBLIC_URL}/images/customer_support.png`,
    },
  ];

  return (
    <>
      <div
        style={{
          backgroundColor: props.color,
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/CircleBg.png)`,
          backgroundSize: "370px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
        }}
        className="w-full mx-auto  mt-[62px] py-[30px] bg-[length:370px] bg-opacity-[0.15] border rounded-3xl"
      >
        <div className="w-[90%] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[85px]  rounded-[20px]  ">
          <div className=" font-Trueno flex flex-col   ">
            <div className="font-Inter font-bold text-3xl md:text-[36px] leading-[43.2px] text-center md:text-left">
              Why our customers
              <br />
              <span style={{ color: props.TextColor }} className=" mt-[23px]">
                choose us
              </span>
            </div>
            <p className="font-Roboto font-normal text-base md:text-[18px] leading-[31px] mt-[23px] text-center md:text-left">
              Karsaaz App is the largest online platform for home maintenance,
              beauty and tailoring services in Pakistan. Choosing Karsaaz for
              your services means choosing reliability, expertise, transparency,
              and a commitment to customer satisfaction. With our comprehensive
              range of services and dedication to quality, you will be in
              excellent hands.
            </p>

            <div className=" hidden md:flex flex-col gap-6  mt-[53px]">
              <h2 className="font-Inter font-bold text-3xl md:text-[36px] leading-[43.2px] text-center md:text-left">
                Get more done with Karsaaz Mobile App
              </h2>
              <div className="flex items-center justify-between">
                <PlayStoreButton />
                <AppStoreButton />
              </div>
            </div>
          </div>

          <div className="block md:hidden lg:block">
            <div className="relative flex  justify-center">
              <img
                src={`${process.env.PUBLIC_URL}/images/IPhone12Icon.svg`}
                alt="Iphone"
                className="z-10 hidden lg:block  "
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/CurrlyIcon.png`}
                alt=""
                className=" h-[90.9px] absolute top-[78%] lg:left-[62%] hidden lg:block z-0 "
              />
            </div>

            {/* currly Icon for small screen */}

            <div className=" relative ">
              <img
                src={`${process.env.PUBLIC_URL}/images/CurrlyIcon.png`}
                alt=""
                className=" h-[90.9px] absolute top-[-45%] left-[70%] block md:hidden"
              />

              <div className="w-full mx-auto">
                {isLoading ? (
                  <div className="flex flex-col gap-3 justify-between w-full  bg-white drop-shadow-md rounded-2xl p-3">
                    <div className="animate-pulse rounded-xl  h-[325.22px] bg-sky-200"></div>
                  </div>
                ) : (
                  <YoutubeIframe
                    className="rounded-[10px] w-full h-[207px]"
                    src={data?.data?.[0]?.value}
                    title="YouTube video player"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 justify-between gap-4 mx-auto ">
            {CustomerChooseData.map((item) => (
              <CustomerChooseIcons
                key={item.id}
                heading={item.heading}
                picture={item.picture}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerChoosesUs;
