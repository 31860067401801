import React from "react";
import { useEffect } from "react";

const CopyRightSection = () => {
  useEffect(() => {
    //  scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="font-Roboto text-white ">
        <hr className="my-6 border-gray-200 sm:mx-auto  lg:my-8" />
        <div className="flex justify-between ">
          <span className="font-normal text-[14px] md:text-[16px] md:text-lg leading-[34px] pb-5">
            Copyright ©{currentYear} Karsaaz Limited.
          </span>
          <button
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/FooterGotoUpIcon.svg`}
              alt=""
              className="w-11 h-11 mb-5 cursor-pointer capitalize"
            />
          </button>
        </div>
      </div>

      {/* <div className="sm:flex sm:items-center sm:justify-between">
        
    </div>
    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
    <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400 pb-9">© 3 <a href="https://flowbite.com/" className="hover:underline">Flowbite™</a>. All Rights Reserved.
    </span> */}
    </>
  );
};

export default CopyRightSection;
